import React, { useEffect } from "react";
// css import
import "../../App.css";
import { Footer } from "../Footer/Footer";
import { Navbar } from "../Navbar/Navbar";
//import { Nav } from "../Nav/Nav";
import Slider from "../Slider/Slider";
import AOS from "aos";
import "aos/dist/aos.css";

export const Home = () => {
  useEffect(() => {
    document.title = "Excellent Classes";
  }, []);
  AOS.init();
  // marquee styling
  const marqueestyle = {
    color: "#ff323a",
    textDecoration: "none",
  };

  return (
    <div className="homemain">
      {/* marquee */}
      <div id="main" className="sectionm">
        <p className="marquee text-styling">
          {" "}
          Batches for SSC (A1,B1,C1) batches will be starting from 11th of April
          2024 | Batches for SSC (C2) batch and kalina branch will be starting
          from 18th of April 2024 | Batches for HSC (Science section) will be
          starting from 11th of April 2024 | Batches for HSC (commerce section)
          will be starting from 18th of April 2024 | Second Preliminary exams
          and Moderation lecture for XII science and Commerce to begin from
          first week of February 2023 | C.A Foundation for November 2024 batch
          to start from 26th January | Admission opens for the Academic Year
          2024-25 for IX , S.S.C, XII- Commerce and Science with Entrance. |
          Admission in Progress for ICSE grade 8 , 9 & 10 (For Demo Lectures
          Contact{" "}
          <a style={marqueestyle} href="tel:+918433558844">
            @+91 84335 58844
          </a>
          )
        </p>
      </div>
      {/* main slider */}
      <div data-aos="flip-up" className="slider">
        <Slider />
      </div>
      {/* features */}
      <h2 className="h2">Streams & Courses</h2>
      <div className="features">
        <div data-aos="fade-right" className="feature-container">
          <img
            src="/assets/school.png"
            alt="School Section"
          />
          <h3>
            <i aria-hidden="true" className="fas fa-school"></i>&nbsp;
            School Section
          </h3>
          <p>SSC/ICSE/CBSE Board</p>
          <div id="btndiv">
            <button className="btnen" type="button">
              Enquire
            </button>
          </div>
        </div>
        <div data-aos="fade-down" className="feature-container">
          <img
            src="/assets/science.png"
            alt="Science Section"
          />
          <h3>
            <i aria-hidden="true" className="fas fa-universal-access"></i> Science Section
          </h3>
          <p>XI & XII & Entrance </p>
          <div id="btndiv">
            <button className="btnen" type="button">
              Enquire
            </button>
          </div>
        </div>
        <div data-aos="fade-left" className="feature-container">
          <img
            src="https://res.cloudinary.com/dp9icjdvf/image/upload/v1662146578/Excellent%20Classes%20/engineering_g1y2di.jpg"
            alt="Commerce Section"
          />
          <h3>
            <i aria-hidden="true" className="fas fa-university"></i> Commerce Section
          </h3>
          <p>XI, XII, F.Y to T.Y.B.Com, BMS, BAF</p>
          <div id="btndiv">
            <button className="btnen" type="button">
              Enquire
            </button>
          </div>
        </div>
      </div>
      <div className="features">
        <div data-aos="fade-right" className="feature-container">
          <img
            src="https://res.cloudinary.com/dp9icjdvf/image/upload/v1668241015/Excellent%20Classes%20/entrance_czjnng.png"
            alt="Entrance Exams"
          />
          <h3>
            <i className="fas fa-book-open"></i>&nbsp;
            Entrance Exams
          </h3>
          <p>NEET, JEE (M), MHT-CET</p>
          <div id="btndiv">
            <button className="btnen" type="button">
              Enquire
            </button>
          </div>
        </div>
        <div data-aos="fade-left" className="feature-container">
          <img
            src="https://res.cloudinary.com/dp9icjdvf/image/upload/v1667447770/Excellent%20Classes%20/competitive_xbbk7s.jpg"
            alt="Competitive Exams"
          />
          <h3>
            <i className="far fa-check-square"></i> Competitive Exams
          </h3>
          <p>CA Foundation</p>
          <div id="btndiv">
            <button className="btnen" type="button">
              Enquire
            </button>
          </div>
        </div>
      </div>
      <hr></hr>
      {/* portfolio */}
      <div className="portfolio">
        <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
          Why Choose us?
        </h2>
        <div className="content">
          <div className="projects">
            <div className="project">
              <div className="project-image">
                <img
                  src="https://res.cloudinary.com/dp9icjdvf/image/upload/v1662401558/Excellent%20Classes%20/10582918_736431616403881_7476763419006628785_o_phv2ec.jpg"
                  alt=""
                />
              </div>
              <div className="project-title">
                <h2 style={{ textAlign: "center" }}>
                  Message from the Founder
                </h2>
              </div>
              <div className="project-description">
                <p>
                  At Excellent Classes, we provide a conducive atmosphere to our
                  students, where they are encouraged to channelize their
                  potential in the pursuit of excellence. The talent, skills and
                  abilities of each student are identified, nurtured and
                  encouraged so that he/she is able to perform better with
                  confidence. We strive hard to teach and realize each students
                  how to succeed in today&rsquo;s competitive environment
                  without losing sight of values & discipline. At our institute
                  we believe our teachers and co-ordinators needs to be humble,
                  accessible and invite participation in dialogues.
                </p>
              </div>
            </div>
            <h2 style={{ textAlign: "center", fontWeight: "bold" }}>Awards</h2>
            <div className="project">
              <div className="project-image">
                <img
                  src="https://excellentclasses.in/wp-content/uploads/2021/10/PHOTO-2021-08-13-20-04-35_1.jpg"
                  alt=""
                />
              </div>
              <div className="project-title">
                <h2 style={{ textAlign: "center" }}>Pioneers 2021</h2>
              </div>
              <div className="project-description">
                <p>
                  We at Excellent strongly believe that the best faculty creates
                  the best students and that is why we have a faculty who are
                  masters in their subjects. We offer our students expertise
                  that they would never experience anywhere else. We invest in
                  training and developing their skills, thus providing our
                  students a complete upgraded education. Qualified and
                  experience faculty accelerate the learning process.
                </p>
              </div>
            </div>
            <div className="project">
              <div className="project-image">
                <img
                  src="https://res.cloudinary.com/dp9icjdvf/image/upload/v1665167946/Excellent%20Classes%20/international-550x400_uj6jbw.png"
                  alt=""
                />
              </div>
              <div className="project-title">
                <h2 style={{ textAlign: "center" }}>
                  International Achievers Award
                </h2>
              </div>
              <div className="project-description">
                <p>
                  I extend my warm welcome once again to all the students and
                  parents to a new academic year. Our hard work, dedication,
                  tried and tested practices have produced excellent results for
                  our students. Our strong team culture and emphasis on our core
                  values has propelled us to be one of the best classes. Parents
                  are the most strengthening power in moulding the future of
                  their children. Their consistent support empowers us to do
                  more. I pay my gratitude to them for putting their trust in
                  us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
      data-aos="zoom-in"
      data-aos-easing="ease-in-back"
      data-aos-offset="500"
      className="divtestimonials">
      <h2 style={{ textAlign: "center", color: "#121971" }}>Testinomials</h2>
      <div
        className="testimonials"
      >
        <figure>
          <img
            src="https://res.cloudinary.com/dp9icjdvf/image/upload/v1668839194/Excellent%20Classes%20/aa_axcy8e.png"
            alt="rating"
          />
          <figcaption>
            It was a truly valuable learning experience with the Institute. It
            has provided me with confidence and self belief. It has driven me to
            perform to my potential and I owe them to what I am now
          </figcaption>
          <br />
          <figcaption style={{ fontWeight: "bolder", color: "#121971" }}>
            Dr. Vikas Gupta
          </figcaption>
          <p>M.D ( Paediatrician )</p>
        </figure>
        <figure>
          <img
            src="https://res.cloudinary.com/dp9icjdvf/image/upload/v1668839194/Excellent%20Classes%20/aa_axcy8e.png"
            alt="rating"
          />
          <figcaption>
            Before joining this Institute, I wasn't studious and focussed but
            our magical professor Mr Jamil Sir has made my orientation towards
            studies and career very clear. I owe my success greatly to this
            institute and making me what I am.
          </figcaption>
          <br />
          <figcaption style={{ fontWeight: "bolder", color: "#121971" }}>
            Arbaz Qadri
          </figcaption>
          <p>B' Arc ( Consultant & Builder )</p>
        </figure>
        <figure>
          <img
            src="https://res.cloudinary.com/dp9icjdvf/image/upload/v1668839194/Excellent%20Classes%20/aa_axcy8e.png"
            alt="rating"
          />
          <figcaption>
            I am being truly impressed with the conduct of the teachers and the
            management at Excellent. Jamil Sir, especially has been torch bearer
            and I feel honoured to have learnt from such a knowledgeable and
            kind hearted person.
          </figcaption>
          <br />
          <figcaption style={{ fontWeight: "bolder", color: "#121971" }}>
            Urmila Sahu
          </figcaption>
          <p>Chartered Accountanct ( C.A)</p>
        </figure>
      </div>
      <div
      className="linktotestimonials">
        <a href="/Testimonials">View All Testinomials</a>
      </div>
      </div>
    </div>
  );
};
