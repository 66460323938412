import React from "react";
import { Link } from "react-router-dom";
import { Home } from "../MAIN/Home";
import AOS from "aos";
import "aos/dist/aos.css";

// css import
import "./Footer.css";

// Redirect Methods
const startfromtop = ()=>{
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}
const HomeRedirect = (e) => {
  window.open("/", "_self");
};
function getYear() {
  return new Date().getFullYear();
};
const MapOpen = (e) => {
  window.open(
    "https://www.google.com/maps/search/VIP+Villa,+Excellent+Classes,+Kurla+West/@19.081726,72.884674,16z?hl=en-IN",
    "_blank"
  );
};
const SendEmail = (e) => {
  window.open("mailto:info@excellent-classes.com", "_self");
};
const Tele = (e) => {
  window.open("tel:+918433558844", "_self");
};

export const Footer = () => {
  AOS.init();
  return (
    <>
      {/* foomter */}
      <hr style={{ visibility: "visible" }}></hr>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-cta pt-4">
            <div className="row">
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i onClick={MapOpen} className="fas fa-map-marker-alt"></i>
                  <div className="cta-text">
                    <h4 onClick={MapOpen}>Find us</h4>
                    <hr style={{ width: "80px" }} className="linedivide" />
                    <span onClick={MapOpen}>VIP Villa House No.16</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i onClick={Tele} className="fas fa-phone"></i>
                  <div className="cta-text">
                    <h4 onClick={Tele}>Call us</h4>
                    <hr style={{ width: "80px" }} className="linedivide" />
                    <span onClick={Tele}>+91 84335 58844</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i onClick={SendEmail} className="far fa-envelope-open"></i>
                  <div className="cta-text">
                    <h4 onClick={SendEmail}>Mail us</h4>
                    <hr style={{ width: "80px" }} className="linedivide" />
                    <span onClick={SendEmail}>info@excellent-classes.com</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-content pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <a>
                      <img
                        onClick={HomeRedirect}
                        src="https://res.cloudinary.com/dp9icjdvf/image/upload/v1665160673/Excellent%20Classes%20/Banner446_kdorky.png"
                        className="img-fluid"
                        alt="logo"
                      ></img>
                    </a>
                  </div>
                  <div className="footer-text">
                    <p>
                      We at Excellent strongly believe that the best faculty
                      creates the best students and that is why we have a
                      faculty who are masters in their subjects.
                    </p>
                  </div>
                  <div className="footer-social-icon">
                    <a
                      target="_blank"
                      data-aos="zoom-in"
                      rel="noreferrer"
                      href="https://www.facebook.com/kurla.best.classes/"
                    >
                      <i className="fab fa-facebook-f facebook-bg"></i>
                    </a>
                    <a
                      target="_blank"
                      data-aos="zoom-in"
                      rel="noreferrer"
                      href="https://instagram.com/excellentclasses.official"
                    >
                      <i className="fab fa-instagram instagram-bg"></i>
                    </a>
                    <a
                      target="_blank"
                      data-aos="zoom-in"
                      rel="noreferrer"
                      href="https://cutt.ly/fCftK21"
                    >
                      <i className="fab fa-whatsapp whatsapp-bg"></i>
                    </a>
                    <a
                      target="_blank"
                      data-aos="zoom-in"
                      rel="noreferrer"
                      href="https://youtube.com/channel/UCh4NwX5JgN5uahmlMKCcDng"
                    >
                      <i className="fab fa-youtube youtube-bg"></i>
                    </a>
                    <a
                      target="_blank"
                      data-aos="zoom-in"
                      rel="noreferrer"
                      href="https://jsdl.in/DT-432AYBJF"
                    >
                      <i className="fab fa-glide-g justdial-bg"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-40">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Main Menu</h3>
                    <hr style={{ width: "108px" }} className="linedivide" />
                  </div>
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="AboutUs">About</a>
                    </li>
                    <li>
                      <a href="Reviews/Testimonials">Testimonials</a>
                    </li>
                    <li>
                      <a href="Reviews/Results">Results</a>
                    </li>
                    <li>
                      <a href="Gallery">Gallery</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Our Courses</h3>
                    <hr style={{ width: "128px" }} className="linedivide" />
                  </div>
                  <ul>
                    <li>
                      <a href="/Courses/School">School Section</a>
                    </li>
                    <li>
                      <a href="/Courses/Science">Science Section</a>
                    </li>
                    <li>
                      <a href="/Courses/Commerce">Commerce Section</a>
                    </li>
                    <li>
                      <a href="/Courses/Entrance">Entrance Examinations</a>
                    </li>
                    <li>
                      <a href="/Courses/CA">C.A Foundation</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          onClick={startfromtop}
          alt="up"
          className="svg-up"
          src="https://res.cloudinary.com/dp9icjdvf/image/upload/v1667454929/Excellent%20Classes%20/Up_llwgah.png"
        ></img>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                <div className="copyright-text">
                  <p>
                     &copy; 1990 - {getYear()} Excellent Classes, All rights reserved | Developed by{" "}
                    <a target="_blank" href="https://qewertyy.dev">
                      @Qewertyy
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};