// react imports
import React from "react";

// css imports
import "./Slider.css";

// slider imports
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper";

export default function Slider() {
  return (
    <>
      <Swiper
        pagination={{ clickable: true }}
        navigation={true}
        loop={true}
        modules={[Pagination, Navigation, Autoplay]}
        autoplay={true}
        className="mySwiper"
      >
        <SwiperSlide>
          <img
            src="https://res.cloudinary.com/dp9icjdvf/image/upload/v1665663974/Excellent%20Classes%20/img8-2048x1365_jccdwn.jpg"
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="/assets/slide1.png"
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://res.cloudinary.com/dp9icjdvf/image/upload/v1665664178/Excellent%20Classes%20/PHOTO-2021-08-13-20-04-35_2-1024x683_tlnlc9.jpg"
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://res.cloudinary.com/dp9icjdvf/image/upload/v1665664580/Excellent%20Classes%20/banner123-830x446_fp4crl.png"
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://res.cloudinary.com/dp9icjdvf/image/upload/v1665671010/Excellent%20Classes%20/banner234-2048x1058_vaoyne.png"
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://res.cloudinary.com/dp9icjdvf/image/upload/v1665671023/Excellent%20Classes%20/banner345-2048x1039_ckmuzl.png"
            alt=""
          />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
