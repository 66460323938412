import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import './App.css';
import { NotFound404 } from './Components/400/NotFound404';
import AboutUs from './Components/AboutUs/AboutUs';
import CA from './Components/Courses/CA';
import { Commerce } from './Components/Courses/Commerce';
import Entrance from './Components/Courses/Entrance';
import { School } from './Components/Courses/School';
import { Science } from './Components/Courses/Science';
import Gallery from './Components/Gallery/Gallery';
import { Home } from './Components/MAIN/Home';
import Testimonials from './Components/Testimonials/Testimonials';

export default function App() {
  return (
   <Router>
    <Routes>
      <Route exact path="/" element={<Home/>} />
      <Route exact path="Aboutus" element={<AboutUs/>}/>
      <Route exact path="Testimonials" element={<Testimonials/>}/>
      <Route exact path="Gallery" element={<Gallery/>}/>
      <Route exact path="Courses/Science" element={<Science/>}/>
      <Route exact path="Courses/Commerce" element={<Commerce/>}/>
      <Route exact path="Courses/School" element={<School/>}/>
      <Route exact path="Courses/Entrance" element={<Entrance/>}/>
      <Route exact path="Courses/CA" element={<CA/>}/>
      <Route exact path="*" element={<NotFound404/>}/>
    </Routes>
   </Router>
  
  );
}