import React from 'react'
import "./Gallery.css"

function Gallery() {
    return (
        <>
            <div className="message">
                Sorry, your browser does not support CSS Grid. 😅
            </div>
            <section className="section">
                <h1>Gallery</h1>
                <div className="grid">
                    <div className="item">
                    </div>
                    <div className="item item--large">

                    </div>
                    <div className="item item--medium">

                    </div>
                    <div className="item item--large">

                    </div>
                    <div className="item item--full">

                    </div>
                    <div className="item item--medium">

                    </div>
                    <div className="item item--large">

                    </div>
                    <div className="item">

                    </div>
                    <div className="item item--medium">

                    </div>
                    <div className="item item--large">

                    </div>
                    <div className="item">

                    </div>
                    <div className="item item--medium">

                    </div>
                    <div className="item item--medium">

                    </div>
                </div>
            </section>
        </>
    )
}

export default Gallery