import React from 'react'
import "./404.css"

export const NotFound404 = () => {
    const GoBack = (e) => {
        history.back();
    };
    return (
        <div className="main404">
            <div className="container">
                <div className="info-label">
                    <div className="error">
                        <h2 > 404 </h2>
                        <div className="text">
                            <b>Error</b>
                        </div>
                    </div>
                </div>
                <div className="info-text">
                    <h1>Page not found</h1>
                    <a className='backbtn' onClick={GoBack}>Go back<span></span></a>
                </div>
            </div>
        </div>
    )
}
