import "./Nav.css";
import { RiMenu3Line } from "react-icons/ri";
import { FaTimes } from "react-icons/fa";
export const Nav = () => {
  return (
    <nav>
      <div className="nav-wrapper">
        <div className="logo">
          <a href="/">
            <img
              src="https://res.cloudinary.com/dp9icjdvf/image/upload/v1665156805/Excellent%20Classes%20/Banner_nzoei0.png"
              alt=""
            />
          </a>
        </div>
        <input type="radio" name="slider" id="menu-btn" />
        <input type="radio" name="slider" id="close-btn" />
        <ul className="nav-links">
          <label htmlFor="close-btn" className="btn close-btn">
            <FaTimes />
          </label>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/AboutUs">About Us</a>
          </li>
          <li>
            <a href="/Gallery">
              Gallery
            </a>
          </li>
          <li>
            <a href="/Gallery" className="desktop-item">
              Courses
            </a>
            <input type="checkbox" id="showDrop" />
            <label htmlFor="showDrop" className="mobile-item">
              Courses
            </label>
            <ul style={{borderRadius:"0px 0px 20px 20px"}} className="drop-menu">
              <li>
                <a href="/Courses/School">School Section</a>
              </li>
              <li>
                <a href="/Courses/Science">Science Section</a>
              </li>
              <li>
                <a href="/Courses/Commerce">Commerce Section</a>
              </li>
              <li>
                <a href="/Courses/Entrance">Entrance Examinations</a>
              </li>
              <li>
                <a href="/Courses/CA" style={{borderRadius:"0px 0px 20px 20px"}}>C.A Foundation</a>
              </li>
            </ul>
          </li>
          <li>
            <a className="desktop-item">
              Reviews
            </a>
            <input type="checkbox" id="showMega" />
            <label htmlFor="showMega" className="mobile-item">
            Reviews
            </label>
            <div className="mega-box">
              <div className="content">
                <div className="row">
                  <a href="/">
                  <img
                    style={{objectFit:"contain"}}
                    src="https://res.cloudinary.com/dp9icjdvf/image/upload/v1670333895/Excellent%20Classes%20/Banner___tz2jfi.png"
                    alt=""
                  />
                  </a>
                </div>
                <div className="row">
                  <ul className="mega-links">
                    <li>
                      <a href="Reviews">Social Reviews</a>
                    </li>
                    <li>
                      <a href="Reviews/Awards">Awards & Certifications</a>
                    </li>
                    <li>
                      <a href="Reviews/Results">Results</a>
                    </li>
                    <li>
                      <a href="Reviews/Testimonials">Testimonials</a>
                    </li>
                  </ul>
                </div>
                <div className="row">
                  <ul className="mega-links">
                    <li>
                      <a href="#">Testimonials</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <label htmlFor="menu-btn" className="btn menu-btn">
          <RiMenu3Line />
        </label>
      </div>
    </nav>
  );
};
